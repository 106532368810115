import { Button, TextField, Typography, Snackbar,InputAdornment, IconButton,Link } from "@mui/material";
import React, { useState } from "react";
import { useNavigate,Link as RouterLink } from "react-router-dom";
import "./login.css";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import background from "../../assets/summit full logo.jpg"
import {environment} from "../../environment"
import { Visibility, VisibilityOff } from "@mui/icons-material";




function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "email") {
      setEmailError("");
    }
    if (name === "password") {
      setPasswordError("");
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const login = async (e) => {
    e.preventDefault();
    if (!formData.email) {
      setEmailError("Please enter an email address");
      return;
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formData.email)) {
        setEmailError("Please enter a valid email address");
        return;
      }
    }
    if (!formData.password) {
      setPasswordError("Please enter a password");
      return;
    } else {
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
      if (!passwordPattern.test(formData.password)) {
        setPasswordError(
          "Password should be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number"
        );
        return;
      }
    }
    try {
      const res = await axios.post(environment.apiUrl +"/admin/login", {
        email: formData.email,
        password: formData.password,
      });

     console.log("login",res.data.result.token,formData);
      if (res.data.result.status) {
        setAlertSeverity("success");
        setAlertMessage("Sign in Successful!");
        localStorage.setItem("token", res.data.result.token);
        localStorage.setItem("isAuthenticated", true);
        navigate("/listings");
      }
    } catch (err) {
      console.error(err);
      setAlertSeverity("error");
      setAlertMessage(
        `Sign in failed! Invalid Username or password : ${err.message}`
      );
    } finally {
      setOpenSnackbar(true);
    }
  };

  return (
    <div className ="div-container h-100">
      <div className ="row  d-flex justify-content-center h-100">
        <div className = "col-md-5 col-sm-12 mt-4">
        <div className ="d-flex align-items-center justify-content-center">
        {/* <img src={logo} alt="logo" style={{ width: '250px', marginRight: '10px' }}></img> */}
        </div>
        <div className="login-container mt-3">
        <h2>Sign In</h2>
        <Typography component="p" variant="p" className ="mt-4">
          Please sign in to your account
        </Typography>
        <form className="" onSubmit={login}>
        <Typography component="p" variant="p" className ="mt-4">
          *Email
        </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            name="email"
            onChange={handleChange}
            error={!!emailError}
            helperText={emailError}
            className="mt-0 w-80"
          />
          
<Typography component="p" variant="p" className="mt-2">
                *Password
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
                error={!!passwordError}
                helperText={passwordError}
                className="mt-0 w-80"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
                <div className="d-flex justify-content-end mt-2">
  <Link
    component={RouterLink}
    to="/forgotPassword"
    variant="body2"
  >
    Forgot password?
  </Link>
</div>
          <br />
          <div className ="d-flex align-items-center justify-content-center">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="mt-5 submit-button"
          >
            Sign In
          </Button>
          </div>
          
        </form>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
        </div>
        <div className ="col-md-7 p-0 ">
        <img src={background} alt="background" className="b-img h-100 w-100 object-fit-cover" />
        </div>
      </div>
      
    </div>
  );
}

export default Login;
